
import vue from "vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { SalesInvoiceLine } from "../Models/SalesInvoiceLine";
import dtFormatter from "../lib/DateTimeFormatter";

@Component
export default class SalesInvoiceLines extends Vue {
  @Prop() private invoiceLines!: SalesInvoiceLine[];

  private dtFormatter = new dtFormatter();

  formatAmount(value: number): string {
    const formatter = new Intl.NumberFormat("nl-NL", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
    });
    return formatter.format(value);
  }
}
